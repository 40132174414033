<template>
<div class=" bg-brand-100 pt-10 pb-16">
<div class="text-center">
  <h5>Collection</h5>
  <h2 class=" mb-6">{{departmentTitle}}</h2>
</div>
<div class="my-4 text-center hidden md:flex justify-center" v-if="typeof meta.pagination !== 'undefined' && products.length">
  <a href="#" @click.prevent="offset -= 24; pushUrl({offset}); getProducts(); scrollTo(0, 0);" v-if="offset > 0">&lt; Prev</a>
  <a href="#" @click.prevent="offset = 0; pushUrl({offset}); getProducts(); scrollTo(0, 0);" v-if="showFirst" class="mx-2 border-b">1</a>
  <span v-if="showFirst" class="px-2">...</span>
  <a href="#" @click.prevent="offset = (page-1)*24; pushUrl({offset}); getProducts(); (page == currentPage) ? '': scrollTo(0, 0);" v-for="page in pageList" class="mx-2" :class="{'border-b w-5 h-5 flex items-center justify-center': page != currentPage, 'cursor-default border-solid rounded-full border-gray-800 border w-5 h-5 flex items-center justify-center': page == currentPage}">{{ page }}</a>
  <span v-if="showLast" class="px-2">...</span>
  <a href="#" @click.prevent="offset = (meta.pagination.total_pages-1)*24; pushUrl({offset}); getProducts(); scrollTo(0, 0);" v-if="showLast" class="mx-2 border-b w-5 h-5 flex items-center justify-center">{{ meta.pagination.total_pages }}</a>
  <a href="#" @click.prevent="offset += 24; pushUrl({offset}); getProducts(); scrollTo(0, 0);" v-if="offset + 24 < meta.pagination.total">Next &gt;</a>
</div>

  <div class="flex flex-wrap px-4 sm:px-10 md:px-20 lg:px-24 xl:px-32 xxl:px-54 xxxl:px-80 mx-auto" :class="{loading: loading}">

    <aside class="w-full md:w-1/6 filters relative bg-red-900 mb-2 md:mb-0" style="height:max-content;">
      <div class=" mx-auto absolute left-0 right-0" style="z-index:-1;" v-if="loading">
        <div class="w-full placeholder relative mb-6 fles flex-col items-start">
          <div class="h-4 bg-white mb-4 w-10/12 mx-4"></div>
          <div class="h-4 bg-white my-4 w-7/12 mx-4"></div>
          <div class="h-4 bg-white my-4 w-8/12 mx-4"></div>
          <div class="h-4 bg-white my-4 w-11/12 mx-4"></div>
          <div class="h-4 bg-white my-4 w-10/12 mx-4"></div>
        </div>
      </div>
      <ul class="pb-2 flex filters-enabled flex-wrap" v-if="enabledFilters.length">
        <li v-for="filter in enabledFilters" class="inline hidden">
          <span v-if="typeof filter[1] == 'object'">
            <a v-for="item in filter[1]" @click="$refs['filter'+filter[0]+item][0].click()" class="bg-black text-white px-4 py-2 inline-block mr-1 mb-1">{{ item }}</a>
          </span>
          <span v-else>
            <a @click="$refs['filter'+filter[0]+filter[1]][0].click()" class="bg-black text-white px-4 py-2 inline-block mr-1 mb-1">{{ filter[1] }}</a>
          </span>
        </li>
      </ul>
      <div class="filter-all bg-red-900 w-full px-6 text-white mr-2 mt-1 md:pb-6">
        <div class="flex justify-between md:my-4 py-2 sm:py-4 items-center md:hidden cursor-pointer md:border-b close-plus" @click="$event.target.nextSibling.classList.toggle('open'); $event.target.classList.toggle('close-plus')">
          <h3 class="font-bold mb-0 " >Filters</h3><div class="pointer-events-none"><img class="plus " src="/assets/static/plus.svg"></div>
        </div>
              <div class="filter-outer  ">
          <div class="py-2 filter-wrapper" :class="{'open': productFilter.filterTitle == 'Category'}" v-for="productFilter in productFilters" :ref="`${productFilter.categoryGroup}.parent`" v-on:mouseover="() => scroll.target && stopScroll($refs[productFilter.categoryGroup][0])" v-on:mouseout="() => stopScroll($refs[productFilter.categoryGroup][0])">
            <span class="hit-zone before" v-on:mouseover.prevent.stop="() => startScroll(productFilter.categoryGroup, -1)"></span>
            <span class="hit-zone after" v-on:mouseover.prevent.stop="() => startScroll(productFilter.categoryGroup, 1)"></span>
            <aside class="before" v-on:mouseover.prevent.stop="() => startScroll(productFilter.categoryGroup, -1)"></aside>
            <aside class="after"  v-on:mouseover.prevent.stop="() => startScroll(productFilter.categoryGroup, 1)"></aside>
            <div v-if="productFilter.filterType == 'singleSelection' && productFilter.categoryGroup == 'department'">

            </div>
            <h4 v-else class="py-1 mb-0 flex justify-between  border-b pb-2 cursor-pointer" @click.self.prevent="$event.target.parentElement.classList.toggle('open')" style="border-color:rgba(255,255,255,0.2)">
              {{ productFilter.filterTitle }}
              <svg aria-hidden="true" focusable="false" role="presentation" class="w-4 h-4 icon icon--wide icon-chevron-down pointer-events-none cursor-pointer" viewBox="0 0 28 16">
                <path d="M1.57 1.59l12.76 12.77L27.1 1.59" stroke-width="2" stroke="#fff" fill="none" fill-rule="evenodd"></path>
              </svg>
            </h4>
            <ul class="filter-group pt-2" v-if="productFilter.filterType == 'singleSelection' && productFilter.categoryGroup == 'department'"  :ref="productFilter.categoryGroup" v-on:scroll="scrollFollow($event, productFilter.categoryGroup, $refs[`${productFilter.categoryGroup}.parent`][0])">

              <li class="pb-6" v-for="c in productFilter.categories">
                <a href="#" class="filter-single flex items-center text-white text-lg title-case font-medium" :class="{'active': filterActive(productFilter.categoryGroup, c.slug)}" @click.self.prevent="toggleFilter($event, productFilter.categoryGroup, c.slug, false, 'instrument')" :ref="'filter'+productFilter.categoryGroup + c.slug">{{ c.title | capitalCase }} </a>
                <ul class="pt-2 text-sm" v-if="c.subcategories">
                  <li class="py-1 pl-4" v-for="s in c.subcategories">
                    <a href="#" class="text-white title-case filter-other flex items-center" :class="{'active': filterActive(productFilter.categoryGroup, s.slug)}" @click.self.prevent="toggleFilter($event, productFilter.categoryGroup, s.slug, false, 'subInstrument')" :ref="'filter'+productFilter.categoryGroup + s.slug">{{ s.title | capitalCase }}</a>
                  </li>
                </ul>
              </li>

            </ul>
            <ul class="filter-group pt-6" v-else-if="productFilter.filterType == 'singleSelection'"  :ref="productFilter.categoryGroup" v-on:scroll="scrollFollow($event, productFilter.categoryGroup, $refs[`${productFilter.categoryGroup}.parent`][0])">
              <li class="py-2" v-for="c in productFilter.categories">
                <a v-on:mouseover.passive href="#" class="filter-single flex items-center font-medium text-white title-case" :class="{'active': filterActive(productFilter.categoryGroup, c.slug)}" @click.self.prevent="toggleFilter($event, productFilter.categoryGroup, c.slug, false)" :ref="'filter'+productFilter.categoryGroup + c.slug">{{ c.title | capitalCase }} </a>
                <ul class="pt-2 text-sm" v-if="c.subcategories">
                  <li class="py-1 pl-4" v-for="s in c.subcategories">
                    <a v-on:mouseover.passive href="#" class=" text-white filter-other flex items-center" :class="{'active': filterActive(productFilter.categoryGroup, s.slug)}" @click.self.prevent="toggleFilter($event, productFilter.categoryGroup, s.slug, false)" :ref="'filter'+productFilter.categoryGroup + s.slug">{{ s.title | capitalCase }}</a>
                  </li>
                </ul>
              </li>
            </ul>
            <ul class="filter-group pt-4" v-else :ref="productFilter.categoryGroup" v-on:scroll="scrollFollow($event, productFilter.categoryGroup, $refs[`${productFilter.categoryGroup}.parent`][0])">
              <li class="py-1" v-for="c in productFilter.categories">
                <a v-on:mouseover.passive href="#" class="filters-single flex items-center text-sm font-medium text-white title-case" :class="{'active': filterActive(productFilter.categoryGroup, c.slug)}" @click.self.prevent="toggleFilter($event, productFilter.categoryGroup, c.slug, true)" :ref="'filter'+productFilter.categoryGroup + c.slug">{{ c.title | capitalCase }}</a>
                <ul class="pt-2 " v-if="c.subcategories">
                  <li class="py-1 pl-2" v-for="s in c.subcategories">
                    <a v-on:mouseover.passive href="#" class=" text-white title-case filter-other flex items-center" :class="{'active': filterActive(productFilter.categoryGroup, s.slug)}" @click.self.prevent="toggleFilter($event, productFilter.categoryGroup, s.slug, true)" :ref="'filter'+productFilter.categoryGroup + s.slug">{{ s.title | capitalCase }}</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </aside>
    <div class="w-full md:w-5/6 bg-brand-100" >
        <div class="my-4 text-center md:hidden" v-if="typeof meta.pagination !== 'undefined' && products.length">
          <a href="#" @click.prevent="offset -= 24; pushUrl({offset}); getProducts(); scrollTo(0, 0);" v-if="offset > 0">&lt; </a>
          <a href="#" @click.prevent="offset = 0; pushUrl({offset}); getProducts(); scrollTo(0, 0);" v-if="showFirstMob" class="mx-2 border-b">1</a>
          <span v-if="showFirst" class="px-2">...</span>
          <a href="#" @click.prevent="offset = (page-1)*24; pushUrl({offset}); getProducts(); (page == currentPage) ? '': scrollTo(0, 0);" v-for="page in pageListMob" class="mx-2" :class="{'border-b': page != currentPage, 'cursor-default': page == currentPage}">{{ page }}</a>
          <span v-if="showLast" class="px-2">...</span>
          <a href="#" @click.prevent="offset = (meta.pagination.total_pages-1)*24; pushUrl({offset}); getProducts(); scrollTo(0, 0);" v-if="showLastMob" class="mx-2 border-b">{{ meta.pagination.total_pages }}</a>
          <a href="#" @click.prevent="offset += 24; pushUrl({offset}); getProducts(); scrollTo(0, 0);" v-if="offset + 24 < meta.pagination.total"> &gt;</a>
        </div>
      <div class="flex flex-wrap relative overflow-hidden container mx-auto " :class="{loading: loading}" v-if="loading">
        <div class="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 placeholder bg-white p-4 relative mb-6" >
          <div class=" h-64 bg-brand-100 mx-4">

          </div>
          <div class="h-4 bg-brand-100 my-4 w-10/12 mx-4">

          </div>
          <div class="h-4 bg-brand-100 my-4 w-2/3 mx-4">

          </div>
        </div>
        <div class="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 placeholder bg-white p-4 relative mb-6">
          <div class=" h-64 bg-brand-100 mx-4">

          </div>
          <div class="h-4 bg-brand-100 my-4 w-10/12 mx-4">

          </div>
          <div class="h-4 bg-brand-100 my-4 w-2/3 mx-4">

          </div>
        </div>
        <div class="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 placeholder bg-white p-4 relative mb-6">
          <div class=" h-64 bg-brand-100 mx-4">

          </div>
          <div class="h-4 bg-brand-100 my-4 w-10/12 mx-4">

          </div>
          <div class="h-4 bg-brand-100 my-4 w-2/3 mx-4">

          </div>
        </div>
        <div class="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 placeholder bg-white p-4 relative mb-6">
          <div class=" h-64 bg-brand-100 mx-4">

          </div>
          <div class="h-4 bg-brand-100 my-4 w-10/12 mx-4">

          </div>
          <div class="h-4 bg-brand-100 my-4 w-2/3 mx-4">

          </div>
        </div>
        <div class="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 placeholder bg-white p-4 relative mb-6">
          <div class=" h-64 bg-brand-100 mx-4">

          </div>
          <div class="h-4 bg-brand-100 my-4 w-10/12 mx-4">

          </div>
          <div class="h-4 bg-brand-100 my-4 w-2/3 mx-4">

          </div>
        </div>
        <div class="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 placeholder bg-white p-4 relative mb-6">
          <div class=" h-64 bg-brand-100 mx-4">

          </div>
          <div class="h-4 bg-brand-100 my-4 w-10/12 mx-4">

          </div>
          <div class="h-4 bg-brand-100 my-4 w-2/3 mx-4">

          </div>
        </div>
        <div class="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 placeholder bg-white p-4 relative mb-6">
          <div class=" h-64 bg-brand-100 mx-4">

          </div>
          <div class="h-4 bg-brand-100 my-4 w-10/12 mx-4">

          </div>
          <div class="h-4 bg-brand-100 my-4 w-2/3 mx-4">

          </div>
        </div>
        <div class="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 placeholder bg-white p-4 relative mb-6">
          <div class=" h-64 bg-brand-100 mx-4">

          </div>
          <div class="h-4 bg-brand-100 my-4 w-10/12 mx-4">

          </div>
          <div class="h-4 bg-brand-100 my-4 w-2/3 mx-4">

          </div>
        </div>
      </div>
      <div v-else class="flex flex-wrap container mx-auto">
        <div class="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 mb-2" v-for="product in products" >
                    <div class="mx-1 h-full  ">
                <div class="bg-white h-full item_hover">
          <product :product="product" @add-to-cart="addToCart" />
        </div>
        </div>
        </div>
      </div>
      <div class="my-4 text-center hidden md:flex justify-center" v-if="typeof meta.pagination !== 'undefined' && products.length">
        <a href="#" @click.prevent="offset -= 24; pushUrl({offset}); getProducts(); scrollTo(0, 0);" v-if="offset > 0">&lt; Prev</a>
        <a href="#" @click.prevent="offset = 0; pushUrl({offset}); getProducts(); scrollTo(0, 0);" v-if="showFirst" class="mx-2 border-b">1</a>
        <span v-if="showFirst" class="px-2">...</span>
        <a href="#" @click.prevent="offset = (page-1)*24; pushUrl({offset}); getProducts(); (page == currentPage) ? '': scrollTo(0, 0);" v-for="page in pageList" class="mx-2" :class="{'border-b w-5 h-5 flex items-center justify-center': page != currentPage, 'cursor-default border-solid rounded-full border-gray-800 border w-5 h-5 flex items-center justify-center': page == currentPage}">{{ page }}</a>
        <span v-if="showLast" class="px-2">...</span>
        <a href="#" @click.prevent="offset = (meta.pagination.total_pages-1)*24; pushUrl({offset}); getProducts(); scrollTo(0, 0);" v-if="showLast" class="mx-2 border-b">{{ meta.pagination.total_pages }}</a>
        <a href="#" @click.prevent="offset += 24; pushUrl({offset}); getProducts(); scrollTo(0, 0);" v-if="offset + 24 < meta.pagination.total">Next &gt;</a>
      </div>
      <div class="my-4 text-center md:hidden" v-if="typeof meta.pagination !== 'undefined' && products.length">
        <a href="#" @click.prevent="offset -= 24; pushUrl({offset}); getProducts(); scrollTo(0, 0);" v-if="offset > 0">&lt; </a>
        <a href="#" @click.prevent="offset = 0; pushUrl({offset}); getProducts(); scrollTo(0, 0);" v-if="showFirstMob" class="mx-2 border-b">1</a>
        <span v-if="showFirst" class="px-2">...</span>
        <a href="#" @click.prevent="offset = (page-1)*24; pushUrl({offset}); getProducts(); (page == currentPage) ? '': scrollTo(0, 0);" v-for="page in pageListMob" class="mx-2" :class="{'border-b': page != currentPage, 'cursor-default': page == currentPage}">{{ page }}</a>
        <span v-if="showLast" class="px-2">...</span>
        <a href="#" @click.prevent="offset = (meta.pagination.total_pages-1)*24; pushUrl({offset}); getProducts(); scrollTo(0, 0);" v-if="showLastMob" class="mx-2 border-b">{{ meta.pagination.total_pages }}</a>
        <a href="#" @click.prevent="offset += 24; pushUrl({offset}); getProducts(); scrollTo(0, 0);" v-if="offset + 24 < meta.pagination.total"> &gt;</a>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';

const instrumentOrder =  {
    geg: 0, gax: 1, gcg: 2, gbg: 3, gfx: 4,
    gaz: 5, uac: 6, ua: 7, aeg: 8, abg: 9,
    aag: 10, am: 11, ke: 12, keh: 13, kap: 14,
    ks: 15, ka: 16, da: 17, de: 18, dcx: 19,
    ds: 20, dap: 21, dax: 22, eb: 23, es: 24,
    ebe: 25, est: 26, et: 27, eo: 28, os: 29,
    oax: 30, bbx: 31, bwx: 32, ri: 33, rm: 34,
    rh: 35, rmo: 36, rc: 37, rhh: 38, ra: 39,
    pam: 40, pas: 41, pap: 42, pal: 43, rmx: 44,
    paa: 45, ppz: 46, pgx: 47, pux: 48, pfx: 49,
    pdr: 50, pfl: 51, pcx: 52, psx: 53, ptx: 54,
    ptbx: 55, pob: 56, pow: 57, pvx: 58, pvl: 59,
    pem: 60, pbx: 61, pth: 62, pcl: 63, prf: 64,
    pgw: 65, pvo: 66, pch: 67, pma: 68,
};

const subInstrumentOrder = {
    gag: 0, gae: 1, gfb: 2, gfm: 3, gfo: 4,
    gat: 5, gas: 6, gast: 7, gacp: 8, gastr: 9,
    gac: 10, gab: 11, gapi: 12, gec: 13, gao: 14,
    gapa: 15, dch: 16, dcc: 17, dcr: 18, dce: 19,
    dcp: 20, das: 21, dast: 22, dak: 23, dad: 24,
    dab: 25, dask: 26, dasp: 27, dao: 28, oavn: 29,
    oava: 30, oac: 31, bbt: 32, bbtb: 33, bbo: 34,
    bba: 35, bwf: 36, bwc: 37, bws: 38, bwo: 39,
    bwr: 40, bwa: 41, ppp: 42, ppc: 43, ppez: 44,
    ppe: 45, pppm: 46, ppex: 47, ppd: 48, pgm: 49,
    pgs: 50, pgp: 51, pgb: 52, pum: 53, pup: 54,
    pus: 55, pfb: 56, pfm: 57, pfo: 58, pflm: 59,
    pflp: 60, pfls: 61, pcm: 62, pcp: 63, pcs: 64,
    psm: 65, psp: 66, pss: 67, ptm: 68, ptp: 69,
    pts: 70, ptbm: 71, ptbp: 72, ptbs: 73, pobm: 74,
    pobp: 75, pobs: 76, powm: 77, powp: 78, pows: 79,
    pvm: 80, pvep: 81, pvp: 82, pvs: 83, pve: 84,
    pvlm: 85, pvlp: 86, pvls: 87, pbc: 88, pbs: 89,
};

export default {
  name: 'product-listing',
  data() {
    return {
      csrfName: window.csrfTokenName,
      csrfToken: window.csrfTokenValue,
      productFilters: [],
      enabledFilters: this.getQueryFilters(),
      products: [],
      scroll: {
        maxSpeed: 1,
        direction: 0,
        speed: 0,
        active: 0,
        acc: 1,
        visible: {},
      },
      loading: false,
      meta: {},
      offset: this.getQueryVariable('offset') || 0,
      departmentCategoryName: this.getQueryVariable('departmentCategoryName') || null,
      code: this.getQueryVariable('department') || null,
    }
  },
  props: {
      department: String,
      departmentTitle: String,
  },
  mounted() {
    var _this = this;
    setInterval(this.doScroll, 20);
    window.addEventListener('popstate', this.handlePop);
    axios.get('/api/productFilters', {
        params: {
          [this.csrfName]: this.csrfToken,
          mainDepartment: _this.department
        }
      })
      .then(function(response) {
        _this.productFilters = response.data.data[0];
        _this.$nextTick(
          () => _this.productFilters.forEach(filter => {
            const group = filter.categoryGroup
            const target = _this.$refs[filter.categoryGroup][0]
            const parent = _this.$refs[`${filter.categoryGroup}.parent`][0]
            if (target && target.scrollHeight > target.clientHeight) {
              console.log(group)
              target.setAttribute('data-scroll', 'top')
              parent.setAttribute('data-scroll', 'top')
            }
          })
        )

      })
      .catch(function(error) {
        console.log(error);
      });

    this.getProducts();
  },
  computed: {
    pageList() {
        // No pages to show
        if (this.meta.pagination.total_pages === 0)
            return []
        if (this.meta.pagination.total_pages === 1)
            return [this.currentPage]
        if (this.meta.pagination.total_pages < 5)
            return Array.apply(null, Array(this.meta.pagination.total_pages)).map((val, index) => index+1)
        let min = Math.max(this.currentPage-2, 1);
        let max = Math.min(this.currentPage+2, this.meta.pagination.total_pages);
        min = Math.max(max-4, 1)
        max = Math.min(min+4, this.meta.pagination.total_pages)

        const pages = []
        for (let index = min; index <= max; index++)
            pages.push(index)
        return pages;
    },
    showFirst() {
        return this.pageList.length && !this.pageList.includes(1);
    },
    showLast() {
        return this.pageList.length && !this.pageList.includes(this.meta.pagination.total_pages);
    },
    pageListMob() {
        // No pages to show
        if (this.meta.pagination.total_pages === 1)
            return [this.currentPage]
        if (this.meta.pagination.total_pages < 5)
            return []
        let min = Math.max(this.currentPage-1, 1);
        let max = Math.min(this.currentPage+1, this.meta.pagination.total_pages);
        min = Math.max(max-2, 1)
        max = Math.min(min+2, this.meta.pagination.total_pages)

        const pages = []
        for (let index = min; index <= max; index++)
            pages.push(index)
        return pages;
    },
    showFirstMob() {
        return !this.pageListMob.includes(1);
    },
    showLastMob() {
        return !this.pageListMob.includes(this.meta.pagination.total_pages);
    },
    currentPage() {
        return Math.floor(this.offset/24)+1
    },
  },
  methods: {
      scrollTo() {
        window.scrollTo(0, 0);
      },
      scrollFollow(event, source, parent) {
          let target = this.$refs[source][0];
          if (target) {
              if (target.scrollTop === 0) {
                  target.setAttribute('data-scroll', 'top');
                  parent.setAttribute('data-scroll', 'top');
                  this.scroll.visible[source] = {
                      top: false,
                      bottom: true,
                  }
              }
              else if (target.scrollHeight - target.scrollTop === target.clientHeight) {
                  target.setAttribute('data-scroll', 'bottom');
                  parent.setAttribute('data-scroll', 'bottom');
                  this.scroll.visible[source] = {
                      top: true,
                      bottom: false,
                  }
              } else {
                  target.setAttribute('data-scroll', '');
                  parent.setAttribute('data-scroll', '');
                  this.scroll.visible[source] = {
                      top: true,
                      bottom: true,
                  }
              }
          }
      },
    toggleFilter(event, group, value, multiple = false, categoryName = null) {

      let exists = false;
      let _this = this;
      let groupExists = false;

      this.enabledFilters.forEach(function(el, i) {
        if (el[0] == group) {
          // group exists
          groupExists = i;
          if (multiple) {
            let index = el[1].indexOf(value);
            if (index > -1) {
              // value exists
              _this.enabledFilters[i][1].splice(index, 1);
              if (el[1].length == 0) {
                _this.enabledFilters.splice(i, 1);
              }
              exists = true;
            }
          } else {
            if (el[1] == value && el[2] == categoryName) {
              _this.enabledFilters.splice(i, 1);
              exists = true;
            }
          }
        }
      });

      if (group === 'department') {
          this.enabledFilters = this.enabledFilters.filter((el) => el[0] === 'department')
      }

      if (exists) {

        return false;
      }

      // Since we did not return anything yet that means this value does not exist in filters
      if (multiple) {
        if (groupExists !== false) {
          this.enabledFilters[groupExists][1].push(value);
        } else {
          this.enabledFilters.push([group, [value], categoryName]);
        }
      } else {
        if (groupExists !== false) {
          this.enabledFilters.splice(groupExists, 1);
          this.enabledFilters.push([group, value, categoryName]);
        } else {
          this.enabledFilters.push([group, value, categoryName]);
        }
      }

      _this.offset = 0;

      if (group == 'department' && categoryName) {
        _this.departmentCategoryName = categoryName;
      }

      return true;
    },
    isFilterEnabled(group, value, multiple = false) {
      this.enabledFilters.forEach(function(el, i) {
        if (multiple) {
          if (el[0] == group && el[1].indexOf(value) > -1) {
            return true;
          }
        } else {
          if (el[0] == group && el[1] == value) {
            return true
          }
        }
      });

      return false;
    },
    addToCart(id, qty) {
      this.$emit('add-to-cart', id, qty);
    },
    filterActive(group, value) {
        let found = false;
        this.enabledFilters.forEach((item, i) => {
            if (item[0] === group && (Array.isArray(item[1]) ? item[1].includes(value) : item[1] === value))
                found = true;
        });
        return found
    },
    startScroll(target, direction) {
        console.log("Starting scroll ", direction)
        this.scroll.active = true;
        this.scroll.target = this.$refs[target][0];
        this.scroll.direction = direction;
    },
    stopScroll(target) {
        console.log("Ending scroll")
        if (target === this.scroll.target)
            this.scroll.active = false;
    },
    doScroll() {
        if (this.scroll.target) {
            if (!this.scroll.active && this.scroll.speed < 0.001) {
                this.scroll.target = undefined
                this.scroll.speed = 0
            } else {
                console.log("Scrolling");
                this.scroll.speed = (this.scroll.active) ? this.scroll.speed + (this.scroll.direction - this.scroll.speed)*this.scroll.acc : this.scroll.speed*(1-this.scroll.acc);
                let last = this.scroll.target.scrollTop;
                let next = Math.max(Math.min(this.scroll.target.scrollHeight - this.scroll.target.clientHeight, this.scroll.target.scrollTop + this.scroll.speed), 0);
                this.scroll.target.scrollTo(this.scroll.target.scrollLeft, Math.max(Math.min(this.scroll.target.scrollHeight - this.scroll.target.clientHeight, this.scroll.target.scrollTop + this.scroll.speed), 0));
            }
        }

    },
    getProducts() {
      let _this = this;

      let query = {
        [this.csrfName]: this.csrfToken,
        mainDepartment: _this.department,
        offset: _this.offset,
        departmentCategoryName: _this.departmentCategoryName
      };

      this.enabledFilters.forEach(function(el) {
        query[el[0]] = el[1];
      });

      this.loading = true;
      axios.get('/api/products', {
          params: query
        })
        .then(function(response) {
          _this.products = response.data.data;
          _this.meta = response.data.meta;
          console.log(_this.meta);
        })
        .catch(function(error) {
          console.log(error);
        })
        .then(function() {
          _this.loading = false;
        });
    },
    getQueryVariable(variable) {
       var query = window.location.search.substring(1);
       var vars = query.split("&");
       for (var i=0;i<vars.length;i++) {
               var pair = vars[i].split("=");
               if(pair[0] == variable){return pair[1];}
       }
       return(false);
   },
   getQueryFilters() {
       var query = window.location.search.substring(1);
       var vars = query.split("&");
       var filters = []
       for (var i=0; i<vars.length;i++) {
           var pair = vars[i].split('=');
           if (!['offset', 'mainDepartment', 'departmentCategoryName', 'brand'].includes(pair[0])) {
               filters.push([...pair, null])
           }
           if (pair[0] == 'brand') {
               let brands = pair[1].split(',')
               filters.push([pair[0], brands])
           }
       }
       return filters
   },
   buildUrl(options) {
       const base = window.location.pathname;
       let args = Object.keys(options).filter(k => options[k]).map(k => `${k}=${options[k]}`).join('&')
       return `${base}${args.length ? '?' : ''}${args}`
   },
   pushUrl(options={}) {
       console.log("Pushing URL")
       const filters = this.enabledFilters.reduce((opts, filt) => ({...opts, [filt[0]]: filt[1]}), {});
       const opts = {
           offset: this.offset,
           ...filters,
           departmentCategoryName: options.department || filters.department ? this.departmentCategoryName : null,
           ...options,
       }
       window.history.pushState(opts, '', this.buildUrl(opts))
   },
   refreshArgs() {
       this.offset = this.getQueryVariable('offset') || 0
       this.departmentCategoryName = this.getQueryVariable('departmentCategoryName') || null
       this.code = this.getQueryVariable('department') || null
       this.enabledFilters = this.getQueryFilters();
   },
   handlePop(event) {
       console.log(event)
       this.refreshArgs();
       this.getProducts();
   }
  },
  watch: {
    enabledFilters() {
      console.log(this.enabledFilters);
      this.pushUrl()
      this.getProducts();
    }
  }
}
</script>

<style lang="scss" scoped>
.filters-single {
    .active {}
}

.plus{
  transform: rotate(45deg);
  transition: 0.2s;
  transition-timing-function: ease-in-out;
}

.close-plus {

.plus{
  transform: rotate(0deg);
  transition: 0.2s;
    transition-timing-function: ease-in-out;
}
}


.filter-outer {
    @media screen and (max-width: 767px) {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1), opacity 0.5s ease;

        &.open {
            max-height: 2000px;
        }
    }
}

.filter-group {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1), opacity 0.5s ease;

    .filter-wrapper.open & {
        opacity: 1;
        max-height: 800px;
        overflow-y: scroll;
        position: relative;



    }


}

.filter-wrapper {
   .filters-single.active, .filter-other.active{
    padding-left: 20px;
    position: relative;
    font-weight: bold;

    &:before{
      content: "";
      width: 5px;
      height: 5px;
      background-color: #fff;
      position: absolute;
      top:0;
      bottom:0;
      border-radius: 99px;
      margin-right: 8px;
      margin: auto;
      margin-left: -15px;
    }
  }
  .filter-single.active{
    position: relative;
    font-weight: bold;
      padding-left: 26px;
      &:before  {
      content:"";
      position: absolute;
      width:20px;
      background-repeat: no-repeat;
      height:20px;
      margin-right: 8px;
      background-position:center;
      pointer-events: none;
      cursor:pointer;
      left:0;
      background-image:url('/assets/static/close.svg');
  }
}
    h3 {
        cursor: pointer;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        svg {
            pointer-events: none;
            transition: transform 0.2s ease;
            path {
                stroke: currentColor;
            }
        }
    }
    &.open {
        h3 {
            svg {
                transform: rotate(180deg);
            }
        }
    }
}


</style>
