<template>
<Slide noOverlay right :crossIcon="true">
  <div>
    <a id="home" href="/" class="text-white hover:font-bold hover:text-white py-4">
      <span>Home</span>
    </a>
  </div>
  <div class="w-48">
    <a id="home" href="/shop" class="text-white hover:font-bold hover:text-white py-4 ">
      <span>Shop</span>
    </a>
  </div>
</Slide>
</template>

<script>
import {
  Slide
} from 'vue-burger-menu' // import the CSS transitions you wish to use, in this case we are using `Slide`

export default {
  props: {
    mainNodes: Array,
  },
  components: {
    Slide // Register your component
  }
}
</script>
