<template>
<div class="flex flex-row-reverse md:h-16 items-center outer-contain"  :class="{active}" >
    <div class="search-outer  text-center md:my-0 cursor-pointer flex flex-row-reverse items-center overflow-hidden "  :class="{active}" v-on:click="toggleClass()">

<div class="desktop hidden md:block">
  <img class="w-10 h-8 search-icon " src="/assets/static/search-black.svg" alt="">
  <img class="hidden w-6 mx-2 h-6 search-cross" src="/assets/static/plus-dark.svg" alt="">
</div>
<div class="mobile md:hidden">
<span class="h-full absolute top-0 md:relative bottom-0 right-0 hidden  mx-2 search-cross mt-4  z-40">  <img class=" w-12 h-8 " src="/assets/static/plus-dark.svg" alt=""></span>
    <img class=" w-8 h-8 search-icon" src="/assets/static/search-black.svg" alt="">
</div>

    </div>
    <div class="search-contain h-16 bg-white   md:bg-transparent mr-8 w-0 overflow-hidden flex items-center">
      <form class="my-0" :action="action">
          <input ref="search" class="text-gray-900 md:rounded-none md:border-b border-gray-500 w-full text-xs md:w-40 ml-2 my-0 py-2 px-2 rounded" type="search" name="q" placeholder="What are you looking for">
          <!-- <input class="w-48 bg-red-900 hover:bg-red-800 border-none text-white" type="submit" value="Search"> -->
      </form>
    </div>
  </div>
</template>

<style>
.active.search-outer{

  justify-content: center;
}
.active .search-icon{
    display: none;
  }

  .active .search-cross{
      display: block !important;
      transform: rotate(45deg);
    }
    .search-contain{
transition: width 0.3s;
  transition: opacity 0.5s;
width: 0px;
position: absolute;
}
  .active + .search-contain{
        width: 10rem;
        transition: width 0.3s;
  transition: opacity 0.5s;
    }

    @media screen and (max-width:768px){
      .active + .search-contain{
background: #fff;
height: 80px;
padding-left:20px;
  width: 100%;
position: absolute;
left: 0;
        }

        .active + .search-contain form{
width:100%;
          }

        .active.outer-contain{
                      position: absolute;
                      right:0;
                      background: #fff;
                      height: 80px;
                      top:0;
                      left:0;
        }
    }
</style>

<script>

export default {
  props:{
    action: String,
  },
  data() {
    return {
      active: false,
    }
  },
  mounted() {

  },
  methods: {
    toggleClass: function(event){
      this.active = !this.active;
      this.$nextTick(() => {
  this.$refs.search.focus()
})
   }
  }
}
</script>
