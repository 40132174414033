<template>
<div :class="' fixed inset-0 flex z-50 items-center justify-center ' + (show ? 'fixed' : 'hidden')" style="background:#0003; z-index:100;">
    <div class="popup-contain relative h-full sm:h-auto">

        <div v-on:click="() => onClose()" v-body-scroll-lock="show" class="max-w-2xl mx-auto shadow-md popup-inner overflow-y-scroll overflow-x-hidden relative" v-if="show">
            <slot></slot>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: ['show'],
    methods: {
        onClose() {
            this.$emit('close');
        }
    }
}
</script>
