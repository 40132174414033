import $ from 'jquery';

export default function init() {

	$(document).on('change', 'select.address-country', function ()
	{
	    // get the value of the selected country.
			console.log("Change event triggered");
	    var cid = $(this).val();
	    var $states = $(this).closest('.addressBox').find('select.address-stateId');
	    var $stateName = $(this).closest('.addressBox').find('input.address-stateName');
	    $states.find('option').remove();

	    if (states.hasOwnProperty(cid))
	    {
	        // We have states for this country, show the states drop down.
	        $states.removeClass('hidden');
	        $states.attr('name', $states.data('modelname')+'[stateValue]');

	        // We have states for this country, hide the stateName input.
	        $stateName.removeAttr('name');
	        $stateName.addClass('hidden');
	        $stateName.val('');

	        // Add all states as options to drop down.
	        for (var id in states[cid])
	        {
	            var state = states[cid][id];
	            var $option = $('<option/>');
	            $option.attr('value', id).text(state);
	            $states.append($option);
	        }

	    }else{
	        // hide the states dropdown, since this country has none.
	        $states.addClass('hidden');
	        $states.removeAttr('name');

	        // show the stateName
	        $stateName.removeClass('hidden');
	        $stateName.attr('name', $stateName.data('modelname')+'[stateValue]');
	    }

	});
	$('.address-fieldset select').addClass('form-control input-sm');
	//$("select.address-country").change();

	var $estimateShippingMethodsContainer = $('.estimate-shipping-methods');
	var $estimateFieldsContainer = $('.estimate-fields');
	var $estimateShowFieldsButton = $('.estimate-show-fields');
	var $estimateBilling = $('.estimate-billing');
	var $estimateBillingSameAsShipping = $('input[name="estimatedBillingAddressSameAsShipping"]');
	var $estimateCountrySelects = $('select.estimate-country');

	// Setup "same as" checkbox
	if ($estimateBillingSameAsShipping.prop('checked')) {
	    $estimateBilling.addClass('hidden').find('input,select').attr('disabled', 'disabled').prop('disabled', 'disabled');
	}

	// Handle the change event for the "same as" checkbox
	$estimateBillingSameAsShipping.change(function(ev) {
	    var $this = $(this);
	    if ($this.prop('checked')) {
	        $estimateBilling.addClass('hidden').find('input,select').attr('disabled', 'disabled').prop('disabled', 'disabled');
	    } else {
	        $estimateBilling.removeClass('hidden').find('input,select').attr('disabled', '').prop('disabled', '');
	    }
	});

	// Setup initial state Showing/hiding of the shipping method selection
	if ($estimateShippingMethodsContainer.find('.shipping-select').length) {
	    $estimateFieldsContainer.addClass('hidden');
	    $estimateFieldsContainer.find('input,select').attr('disabled', 'disabled').prop('disabled', 'disabled');
	} else {
	    $estimateShippingMethodsContainer.addClass('hidden');
	    $estimateShippingMethodsContainer.find('input,select').attr('disabled', 'disabled').prop('disabled', 'disabled');;
	}

	// Handle click event for returning to the estimate fields
	$estimateShowFieldsButton.click(function(ev) {
	    ev.preventDefault();

	    if ($estimateFieldsContainer.hasClass('hidden')) {
	        $estimateFieldsContainer.removeClass('hidden');
	        $estimateFieldsContainer.find('input,select').attr('disabled', '').prop('disabled', '');
	        $estimateShippingMethodsContainer.addClass('hidden');
	        $estimateShippingMethodsContainer.find('input,select').attr('disabled', 'disabled').prop('disabled', 'disabled');
	    } else {
	        $estimateFieldsContainer.addClass('hidden');
	        $estimateFieldsContainer.find('input,select').attr('disabled', 'disabled').prop('disabled', 'disabled');
	        $estimateShippingMethodsContainer.removeClass('hidden');
	        $estimateShippingMethodsContainer.find('input,select').attr('disabled', '').prop('disabled', '');
	    }
	});

	// Handle change event for country selection for both shipping and billing
	$estimateCountrySelects.change(function () {
		console.log("Change event triggered");
	    // get the value of the selected country.
	    var cid = $(this).val();
	    var $box = $('.estimate-state-box[data-modelname="'+$(this).data('modelname')+'"]');
	    var $states = $box.find('select.address-stateId');
	    var $stateName = $box.find('input.address-stateName');
	    $states.find('option').remove();

	    if (states.hasOwnProperty(cid))
	    {
	        // We have states for this country, show the states drop down.
	        $states.removeClass('hidden');
	        $states.attr('name', $states.data('modelname')+'[stateValue]');

	        // We have states for this country, hide the stateName input.
	        $stateName.removeAttr('name');
	        $stateName.addClass('hidden');
	        $stateName.val('');

	        // Add all states as options to drop down.
	        for (var id in states[cid])
	        {
	            var state = states[cid][id];
	            var $option = $('<option/>');
	            $option.attr('value', id).text(state);
	            $states.append($option);
	        }

	    }else{
	        // hide the states dropdown, since this country has none.
	        $states.addClass('hidden');
	        $states.removeAttr('name');

	        // show the stateName
	        $stateName.removeClass('hidden');
	        $stateName.attr('name', $stateName.data('modelname')+'[stateValue]');
	    }

	});


	var $fieldsets = $('.address-fieldset');

	$fieldsets.each(function () {
	    var $addressFieldset = $(this);

	    $('.address-select', $addressFieldset).each(function () {

	        this.$element = $(this);
	        this.$radio = $('.radio input', this.$element);
	        this.$body = $('.body', this.$element);

	        this.$radio.change($.proxy(function () {
	            $('.body', $addressFieldset).addClass('hidden');
	            this.$body.removeClass('hidden');
	        }, this));

	        if (this.$radio.is(':checked')) {
	        this.$body.removeClass('hidden');
	        }
	    });

	});




}
