<template>
   <div class="calculator-container">
      <div class="calculator flex-col sm:flex-row flex items-stretch">
         <div class="calculator-form">
            <custom-input type="hidden"    v-model="loanAmount"/>
            <custom-input class="mb-2." type="number" label="Down Payment" prefix="$" :in-valid="invalidDownpayment" v-model="downPayment"/>
            <div>
               <label class="customlbl"> <h5 class="mb-0">Loan duration is <span class="text-lg text-white  bg-red-900 px-1 rounded-full"> {{ duration }}</span> months</h5></label>
               <br/>
               <custom-input type="hidden"  class="input-date" :in-valid="invalidStartDate" v-model="startDate"/>
               <range-slider
                  class="slider"
                  v-model="duration"
                  label="Loan duration"
                  :min="min"
                  :max="max"
                  step="1"
                  ></range-slider>
               <!-- <vue-range-slider ref="slider" v-model="duration"
                  label="Loan duration" :min="min"
                  :max="max" @drag-end="calculate"> </vue-range-slider>-->
            </div>
            <!--<div>
               <custom-input type="number" label="Interest rate" sufix="%" class="input-interest" :inValid="invalidInterestRate" @blur="calculate" v-model="interestRate"/>

               </div>

               <custom-button @click="calculate">Calculate</custom-button>-->
         </div>
         <div class="calculator-result">
            <h5 class="mb-2">Monthly payment</h5>
            <p class="label-monthly-payment">{{ monthlyPayment }}</p>
            <br>
            <!--<p>Total Principal Paid <span class="font--bold">{{ numFormat(principalTotal) }}</span></p>
               <p>Total Interest Paid <span class="font--bold">{{ numFormat(interestTotal) }}</span></p>-->
            <h5 class="mb-2">Payoff Date</h5>
            <p class="label-payoff-date text-lg font-bold">{{ payoffDate }}</p>
         </div>
      </div>
      <!--<div class="downpdf">
         <button @click="generatePdf">PDF</button>
         </div>
         <p v-show="amortizationList.length > 0" class="table-title font--bold">Payment Schedule</p>
         <Table v-show="amortizationList.length > 0">
           <template slot="header">
               <th v-for="(item, index) in headers" :key="index" :class="item.resposive ? 'responsive' : ''">{{ item.label }}</th>
           </template>
           <template slot="body">
               <tr v-for="(item, index) in amortizationList" :key="index">
                   <td v-for="(label, subIndex) in item" :key="subIndex" :class="subIndex == 'monthlyPayment' || subIndex == 'principal' ? 'responsive' : ''">{{ label }}</td>
               </tr>
           </template>
         </Table>-->
   </div>
</template>
<script>
import CustomButton from '@/components/core/CustomButton.vue'
import CustomInput from '@/components/core/CustomInput.vue'
import ToggleButton from '@/components/core/ToggleButton.vue'
import Table from '@/components/Table.vue'
import { numFormat, dateFormat } from '@/utils/Utils'

//import jsPDF from 'jspdf'
//import 'jspdf-autotable'
//import html2canvas from "html2canvas"

import RangeSlider from 'vue-range-slider'
// you probably need to import built-in style
import 'vue-range-slider/dist/vue-range-slider.css'
import vClickOutside from 'v-click-outside'
//import 'vue-range-component/dist/vue-range-slider.css'
//import VueRangeSlider from 'vue-range-component'

//VueRangeSlider , RangeSlider,
import Vue from 'vue'
Vue.use(vClickOutside)
export default {
    components: {
        CustomButton, CustomInput, ToggleButton, Table,  RangeSlider
    },
	props: ['info'],
    mounted() {
        this.calculate();

    },
	onClickOutside (event) {
        console.log('Clicked outside. Event: ', event)
      },
    data(){
        return {
		    duration: 6,
			min: 6,
			max: 18,
            loanAmount: (this.info.loanamt*1)||1000,
            downPayment: 100,
            monthlyPayment: 0,
            //interestRate: 10,
           // interestTotal: 0,
           //Payment Date, Monthly Payment, Principal Paid, Interest Paid, Total Paid
           // isYearly: false,
            startDate: new Date(),
            headers: [
                    { label: "Payment Date" },
                    { label: "Monthly Payment", resposive: true },
                    { label: "Total Paid" },
					{ label: "Balance" }
                    ],
		     vcoConfig: {
        handler: this.handler,
        middleware: this.middleware,
        events: ['dblclick', 'click'],
        // Note: The default value is true, but in case you want to activate / deactivate
        //       this directive dynamically use this attribute.
        isActive: true,
        // Note: The default value is true. See "Detecting Iframe Clicks" section
        //       to understand why this behaviour is behind a flag.
        detectIFrame: true
      }
					/*,
            amortizationList: [],
			amortizationListPdf: []*/

        }
    },
    computed: {
        principalTotal() {
            return this.loanAmount - this.downPayment;
        },

        payoffDate() {
            //don't process if has error input
			//console.log("dfdfdfd");
			//console.log(this.$el.querySelector('.custom-input--error') );
            /*if(this.$el && this.$el.querySelector('.custom-input--error')) {
			  console.log("error");
			  return "";
          }*/


            let count = this.duration || 1; //this.isYearly ? this.duration * 12 : this.duration;
			//console.log("duration ="+count);
           // let i = this.interestRate * 0.01 / 12;
			//let i = this.interestRate * 0.01;

			let principalAfterDownPayment = this.principalTotal;

			//console.log("ppppp "+ principalAfterDownPayment);

			//let monthly_intt = total_intt/count;

			let monthly_principal = (this.principalTotal/count);

			let result = monthly_principal;


            this.monthlyPayment = this.numFormat(this.roundNumber(result,2));
           // this.amortizationList = [];
			//this.amortizationListPdf = [];


			let principal=0,  date = new Date(this.startDate),
                 balance = this.principalTotal,totalPaid=0;

           //Payment Date, Monthly Payment, Principal Paid, Interest Paid, Total Paid
            while(count > 0) {
                //set next month
                date.setMonth(date.getMonth() + 1);
				totalPaid += result;
                principal += monthly_principal; //result - interest;

                if(balance < 0) balance = 0;

				balance -= result;

               /* this.amortizationList.push({
                    date: this.dateFormat(date),
                    monthlyPayment: this.numFormat(this.roundNumber(result,2)),
                    totalPaid: this.numFormat(this.roundNumber(totalPaid,2)),
					balance: this.numFormat(this.roundNumber(balance,2))
                });
				this.amortizationListPdf.push([this.dateFormat(date),
											   this.numFormat(this.roundNumber(result,2)),
				                               this.numFormat(this.roundNumber(totalPaid,2)),
											   this.numFormat(this.roundNumber(balance,2))
											   ]);*/

                count--;
            }
            //this.interestTotal = totalInterest;
            let payoffDate = this.dateFormat(date, true);
            this.$emit("update", {
                downPayment: this.downPayment,
                monthlyPayment: this.monthlyPayment,
                payoffDate,
                duration: this.duration
            })
            return payoffDate;
        },
    },
	created() {
	this.setDownPayment();
    this.setRangeVaue();
  },
    methods: {

	    getMinDepoAmtAndPct() {
			var loan_amt = Number(this.loanAmount);
			var deposit_amt = Number(this.downPayment);
			var min_depo_amt = 0;
			var min_pct = '10%';
			if(loan_amt <=2999){
				min_pct = '10%';
				min_depo_amt =  loan_amt*0.1;
			}
			else{
				min_pct = '20%';
				min_depo_amt =  loan_amt*0.2;
			}
			var max_duration = 24;
			var min_duration =6;


			if(loan_amt <=800){
				max_duration = 6;
				min_duration =6;
			}
			else if(loan_amt>800 && loan_amt<=2999) {
				max_duration = 18;
				min_duration =6;
			}


			return {min_pct:min_pct, min_depo_amt:min_depo_amt,max_duration:max_duration,min_duration:min_duration}
		},
	    checkValidAmt() {

			var deposit_amt = Number(this.downPayment);
			var objDepoPct = this.getMinDepoAmtAndPct();
			var min_depo_amt =objDepoPct.min_depo_amt;
			var min_pct =objDepoPct.min_pct;

			//console.log(objDepoPct);
			if(deposit_amt < min_depo_amt){
			   var msg = 'Minimum deposit amount should be '+min_pct+' of Loan amount which is $'+Math.ceil(min_depo_amt);
			   return {ind:false, msg:msg};
			}
			return {ind:true, msg:''};

		},
		setDownPayment() {
			var objDepoPct = this.getMinDepoAmtAndPct();
			var min_depo_amt =objDepoPct.min_depo_amt;
			this.downPayment = Math.ceil(min_depo_amt);
		},
        /*invalidloanAmount() {
			this.setDownPayment();
			if(Number(this.loanAmount) < 100 || Number(this.loanAmount) < Number(this.downPayment)) {
				return "Price to low";
			}
			this.invalidDownpayment();
			this.setRangeVaue();

        },*/
        invalidDownpayment() {
		    //console.log("ppppp");
            if(Number(this.loanAmount) < Number(this.downPayment)) {
				return "Invalid downpayment";
			}
			var chk = this.checkValidAmt();
			if(!(chk.ind)){
			  return chk.msg;
			}
            console.log("about to set range value")
			this.setRangeVaue();
            return false;
        },
		setRangeVaue() {
            console.log("Setting range value")
			var objDepoPct = this.getMinDepoAmtAndPct();
			this.max = objDepoPct.max_duration;
			this.min =objDepoPct.min_duration;
			this.duration = Math.min(Math.max(this.min, this.duration), this.max);
			//console.log(objDepoPct);
		},
       /* invalidInterestRate() {
            if(Number(this.interestRate) <= 0 || Number(this.interestRate) > 98) return "Invalid rate";
            return false;
        },*/
        invalidStartDate() {
            let date =  new Date(this.startDate)
            let invalid = date == 'Invalid Date';
            if(invalid || date.getFullYear().toString().length != 4) return "Invalid date"
            this.startDate = this.dateFormat(date);
            return false;
        },
		 roundNumber(num, scale) {

			if(!("" + num).includes("e")) {
				return +(Math.round(num + "e+" + scale)  + "e-" + scale);
			} else {
				var arr = ("" + num).split("e");
				var sig = ""
				if(+arr[1] + scale > 0) {
				sig = "+";
			}
			return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
			}
		},/*
        calculate(e) {
            //don't process if has error input
			//console.log("dfdfdfd");
			//console.log(this.$el.querySelector('.custom-input--error') );
            if(this.$el.querySelector('.custom-input--error')) {
			  console.log("error");
			  return false;
			}


            let count = this.duration || 1; //this.isYearly ? this.duration * 12 : this.duration;
			//console.log("duration ="+count);
           // let i = this.interestRate * 0.01 / 12;
			//let i = this.interestRate * 0.01;

			let principalAfterDownPayment = this.principalTotal;

			//console.log("ppppp "+ principalAfterDownPayment);

			//let monthly_intt = total_intt/count;

			let monthly_principal = (this.principalTotal/count);

			let result = monthly_principal;


            this.monthlyPayment = this.numFormat(this.roundNumber(result,2));
           // this.amortizationList = [];
			//this.amortizationListPdf = [];


			let principal=0,  date = new Date(this.startDate),
                 balance = this.principalTotal,totalPaid=0;

           //Payment Date, Monthly Payment, Principal Paid, Interest Paid, Total Paid
            while(count > 0) {
                //set next month
                date.setMonth(date.getMonth() + 1);
				totalPaid += result;
                principal += monthly_principal; //result - interest;

                if(balance < 0) balance = 0;

				balance -= result;

               /* this.amortizationList.push({
                    date: this.dateFormat(date),
                    monthlyPayment: this.numFormat(this.roundNumber(result,2)),
                    totalPaid: this.numFormat(this.roundNumber(totalPaid,2)),
					balance: this.numFormat(this.roundNumber(balance,2))
                });
				this.amortizationListPdf.push([this.dateFormat(date),
											   this.numFormat(this.roundNumber(result,2)),
				                               this.numFormat(this.roundNumber(totalPaid,2)),
											   this.numFormat(this.roundNumber(balance,2))
											   ]);

                count--;
            }
            //this.interestTotal = totalInterest;
            this.payoffDate = this.dateFormat(date, true);
        },*/
        numFormat,
        dateFormat
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_color.scss";
.calculator {
    width: 100%;
    margin: 0 0 0px;
    padding: 0;
    border-right: 2px solid $border-color;
    > div {
        display: inline-block;
        width: 50%;
        padding: 20px;
        vertical-align: top;
    }
}
.range-slider-fill{
  background-color: $primary !important;
}

.calculator-form {
    background-color: $secondary;
}

.calculator-result {
    text-align: center;
}

.input-duration {
    width: calc(100% - 140px);
    display: inline-block;
}

.input-interest {
    width: 120px;
    display: inline-block;
}
.input-date {
    width: calc(100% - 160px);
    display: inline-block;
    vertical-align: top;
    margin-left: 40px;
}

.label-monthly-payment {
    font-size: 2.6em;
    font-weight: 700;
}

.label-payoff-date {

}

.table-title {
    margin-top: 40px;
    font-size: 1.2em;
}

@media only screen and (max-width:767px) {
    .calculator {
        > div {
            width: 100%;
        }
    }
    .responsive {
        display: none;
    }
}

@media only screen and (max-width: 414px) {
    .calculator {
        border: 0;
    }
    .input-interest {
        width: 90px;
    }
    .input-date {
        width: calc(100% - 130px);
    }
    .table-title {
        text-align: center;
    }
}
.slider {
  /* overwrite slider styles */
  width: 200px;
}
label.customlbl  { font-size: 16px;
    color: #102E5A;
    -webkit-font-smoothing: antialiased;}
.downpdf {  margin: 8px;
  float:right; width:20%}
</style>
