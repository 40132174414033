import Vue from 'vue';
import "./css/main.scss";
import Components from "./components";
import VueAwesomeSwiper from 'vue-awesome-swiper'
import axios from 'axios';
import EventBus from './EventBus'
import $ from 'jquery';
require('jquery-serializejson');
import cart from './modules/cart';
import VueCase from 'vue-case'


cart();

// import style
import 'swiper/css/swiper.css'



Vue.use(VueAwesomeSwiper)
Vue.use(VueCase)
// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// breakpoint where swiper will be destroyed
// and switches to a dual-column layout
const breakpoint = window.matchMedia( '(min-width:31.25em)' );
// keep track of swiper instances to destroy later
let mySwiper;
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
const breakpointChecker = function() {
   // if larger viewport and multi-row layout needed
   if ( breakpoint.matches === true ) {
      // clean up old instances and inline styles when available
      if ( mySwiper !== undefined ) mySwiper.destroy( true, true );
      // or/and do nothing
      return;
   // else if a small viewport and single column layout needed
   } else if ( breakpoint.matches === false ) {
      // fire small viewport version of swiper
      return enableSwiper();
   }
};




//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
const enableSwiper = function() {
   mySwiper = new Swiper ('.swiper-container', {
      loop: true,
      slidesPerView: 'auto',
      centeredSlides: true,
      a11y: true,
      keyboardControl: true,
      grabCursor: true,
      // pagination
      pagination: '.swiper-pagination',
      paginationClickable: true,
   });
};
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
// keep an eye on viewport size changes


if ($("#app").length) {
  new Vue({
    el: "#app",
    delimiters: ["[[", "]]"],
    components: {
     LocalSwiper: VueAwesomeSwiper.swiper,
     LocalSlide: VueAwesomeSwiper.swiperSlide,
   },
   data() {
    return {
      isActive: true,
      csrfName: window.csrfTokenName,
      csrfToken: window.csrfTokenValue,
      currentDepartment: window.currentDepartment,
      importStatus: {},
      couponError: '',
      showNav: false,
      cartOpen: false,
      cartSummary: false,
      showPriceMatch: false,
      showPriceMatchForm: false,
      priceMatchPhoneNumber: '',
      priceMatchComment: '',
      features: {
        priceMatch: window.showPriceMatch,
      },
      loan: {},
      cart: {
        numberOfItems: 0,
        totalPrice: '',
        items: {}
      },
      cartLoading: false,
      cartAddressForm: {
          billingAddressSameAsShipping: true
      },
      swiperOptionTop: {
        loop: true,
        zoom: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 0,
        dynamicBullets:true,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true,
        breakpoints:{
          600: {
             spaceBetween: 10,
             dynamicBullets:false,
     },
        }
      },
      swiperOptionCollection: {
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
      },
      department: {
        slidesPerView: 'auto',
        spaceBetween: 10,
        freeMode: true,
        draggable: true,
        navigation: {
          nextEl: '.next_dep',
          prevEl: '.prev_dep'
        },
      },
      departmentNav: {
        slidesPerView: 'auto',
        spaceBetween: 20,
        centeredSlides: true,
        loop:true,
        navigation: {
          nextEl: '.next_depNav',
          prevEl: '.prev_depNav'
        },
      },
      departmentsFull: {
        slidesPerView: 'auto',
        spaceBetween: 20,
        centeredSlides: true,
        loop:true,


      },
      collection:{
        slidesPerView: 'auto',
        spaceBetween: 0,
        centeredSlides: true,
        freeMode: true,
        draggable: true,
    },
    shippingQuoteElem: undefined,
    }
  },
  created() {
    this.cartAddress();
  },
  mounted() {

    this.cartUpdate();
    this.feedHeartBeat();

    if (typeof this.$refs.swiperTop !== 'undefined') {
      this.$nextTick(() => {
        const swiperTop = this.$refs.swiperTop.$swiper
        const swiperThumbs = this.$refs.swiperThumbs.$swiper
        swiperTop.controller.control = swiperThumbs
        swiperThumbs.controller.control = swiperTop
      })
      var swiperTop = this.$refs.swiperTop.$swiper
      var swiperSlide = document.getElementsByClassName('swiper-slide')
    }

    if (typeof this.$refs.departmentNav !== 'undefined') {
        this.$nextTick(() => {
            let index = 0;
            let slides = this.$refs.departmentNav.$children
            slides.forEach((child, i) => {
                if (child.$attrs['data-department'] == this.currentDepartment) {
                    index = i;
                }
            })

            this.$refs.departmentNav.$swiper.slideTo(index);
        })
    }

    if (document.getElementById('price-match-form')) {
        document.getElementById('price-match-form').addEventListener('click', () => {
            this.showPriceMatchForm = !this.showPriceMatchForm
        })
    }


    // for(var index = 0; index< swiperSlide.length; index++){
    // swiperSlide[index].addEventListener('mouseover',function(e){
    //     swiperTop.zoom.in();
    // })
    //
    // swiperSlide[index].addEventListener('mouseout',function(e){
    //     swiperTop.zoom.out();
    // })
    // }




  },
  methods: {
      feedHeartBeat() {
          fetch('/history/data/import.json')
          .then(r => r.json())
          .then(r => {
              this.importStatus = r;
              setTimeout(this.feedHeartBeat, 60000)
          })
      },
      pendProduct(product) {
        this.cart.items.forEach(_product => {
            if (_product.id === product.id) {
                _product.pending = true;
                console.log("Product pending removal");
            } else {
                _product.pending = false
            }
        })
        this.cart.items = [...this.cart.items]

      },
    toggleClass(){
       this.isActive = !this.isActive;
     },
     ago(ts) {
      let now = +new Date() / 1000;
      let seconds, minutes, hours, days, weeks;
      seconds = (now - ts);
      minutes = seconds / 60;
      hours = minutes / 60;
      days = hours / 24;
      weeks = days / 7;

      if (minutes < 5) {
        return 'just now'
      } else if (hours < 1) {
        return `${Math.floor(minutes)}m ago`
      } else if (days < 1) {
        return `${Math.floor(hours)}h ago`
      } else if (weeks < 1) {
        return `${Math.floor(days)}d ago`
      } else {
        return `${Math.floor(weeks)}w ago`
      }
    },

    captureLoan(loan) {
        this.loan = loan;
    },

    addToCart(purchasableId, qty) {
      /**
       * USAGE:
       *
       *   <a href="#" @click.prevent="addToCart(7, 1)">Add To Cart</a>
       */
      const _this = this;

      axios.post('/actions/commerce/cart/update-cart', {
        'purchasableId': purchasableId,
        'qty': qty,
        [this.csrfName]: this.csrfToken
      })
      .then(function (response) {
        _this.cartOpen = true;
      })
      .catch(function (error) {
        console.log(error);
      });

      EventBus.$emit('addToCart');
    },
    addToCartForm(formId) {
      /**
       * USAGE:
       *
       *   <form id="form-id" v-on:submit.prevent="addToCartForm('form-id')">
       *     <input type="hidden" name="qty" value="1">
       *     <input type="hidden" name="purchasableId" value="{{ product.defaultVariant.id }}">
       *     <button type="submit">Add to cart</button>
       *   </form>
       */
      const _this = this;

      const values = $('#'+formId).serializeJSON();
      console.log(values);

      axios.post('/actions/commerce/cart/update-cart', {
        ...values,
        puchasableId: 'XXX',
      })
      .then(function (response) {
          console.log(response);
        _this.cartOpen = true;
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    cartUpdateProductQuantity(id, qty) {
      const _this = this;

      _this.cartLoading = true;
      axios.post('/actions/commerce/cart/update-cart', {
        'lineItems': {[id]: {'qty': qty}},
        [this.csrfName]: this.csrfToken
    }, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
      .then(function (response) {
        _this.cartUpdate();
      })
      .catch(function (error) {
        console.log(error);
        _this.cartLoading = false;
      });
    },
    cartUpdateProductNote(id, note) {

      this.cartLoading = true;
      axios.post('/actions/commerce/cart/update-cart', {
        'lineItems': {[id]: {'note': note}},
        [this.csrfName]: this.csrfToken
      })
      .then((response) =>{
        this.cartUpdate();
      })
      .catch((error) => {
        console.log(error);
        this.cartLoading = false;
      });
    },
    cartUpdateCouponCode(code) {

      this.cartLoading = true;
      axios.post('/actions/commerce/cart/update-cart', {
        'couponCode': code,
        [this.csrfName]: this.csrfToken
      })
      .then((response) => {
          if (response.data.error) {
              this.couponError = response.data.errors.couponCode && response.data.errors.couponCode[0]
          } else {
              this.couponError = ''
          }
        this.cartUpdate();
      })
      .catch((error) => {
        console.log(error);
        this.cartLoading = false;
      });
    },
    openVariants(id) {
      document.getElementById(id).classList.toggle('open');
    },
    cartUpdate() {
      const _this = this;
      console.log('Updating cart');

      _this.cartLoading = true;
      axios.get('/shop/cart.json', {
        [this.csrfName]: this.csrfToken
      })
      .then(function (response) {
        _this.cart = response.data;
        _this.cart.items.forEach(item => {item.pending = false})
        _this.$nextTick(() => _this.$nextTick(() => _this.shippingQuote()))

      })
      .catch(function (error) {
        console.log(error);
      }).then(function() {
        _this.cartLoading = false;
      });

      // Refresh cart in case it got updated in different tab
      clearTimeout(window.cartRegularUpdate);
      window.cartRegularUpdate = setTimeout(this.cartUpdate, 120000);
    },
    cartAddress() {
      // init
      if (document.getElementsByName('billingAddressSameAsShipping').length) {
        let element = document.getElementsByName('billingAddressSameAsShipping')[0];
        this.cartAddressForm.billingAddressSameAsShipping = element.hasAttribute('checked');
      }
  },
    shippingQuote() {
        this.shippingQuoteElem = document.querySelector('#shipping-quote')
        if (this.shippingQuoteElem) {
            const cartText = this.cart.items.map((item) => `${encodeURIComponent('#' + item.productId)}%20${encodeURIComponent(item.title.replaceAll('&#x20;', ' '))}%20x${encodeURIComponent(item.qty)}%20${encodeURIComponent(item.subtotal)}`).join('%0D%0A')
            this.shippingQuoteElem.setAttribute('href', `mailto:help@barrattsmusic.com.au?subject=Request%20quote%20for%20shipping%20to%20mainland&body=Hi%20Barratts%0D%0A%0D%0AI%20would%20like%20to%20request%20mainland%20shipping%20for%20the%20following%20products%3A%0D%0A%0D%0A${cartText}`)
        }
    }
  },
  watch: {
    cartOpen(value) {
      if (value == true) {
        this.cartUpdate();
      }
    }
  }
  });
}
$('#next_dep').hover(
       function(){ $('.department_swiper').addClass('hovered-next') },
       function(){ $('.department_swiper').removeClass('hovered-next') }
)

$('#prev_dep').hover(
       function(){ $('.department_swiper').addClass('hovered-prev') },
       function(){ $('.department_swiper').removeClass('hovered-prev') }
)
// $( ".purchasableId" ).change(function(e) {
//   $(".purchasableInfo").hide();
//   $("#"+($(this).find(":selected").data('info'))).toggle();
// });
